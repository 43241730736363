<template>
  <div>
    <NavigationBar
      titel="Veranstaltung bearbeiten"
      showBackBtn
      :actions="actions"
    ></NavigationBar>
    <section>
      <template>
        <v-container>
          <v-row justify="center" align="center">
            <v-form
              v-model="helpers.formIsValid"
              ref="form"
              @submit.prevent="updateItem"
            >
              <v-col cols="12">
                <event-manage-details v-model="data"></event-manage-details>
              </v-col>
              <v-col cols="12">
                <event-manage-settings
                  v-model="data.config"
                ></event-manage-settings>
              </v-col>
            </v-form>
          </v-row>
        </v-container>
      </template>
    </section>
  </div>
</template>

<script>
import { db, Timestamp } from "@/firebase";
import { SCHEDULING } from "@/store/modules.js";
import { UPDATE_EVENT } from "@/store/action-types.js";
import NavigationBar from "@/components/_systemwide/NavigationBar.vue";
import EventManageDetails from "@/components/scheduling/EventManageDetails.vue";
import EventManageSettings from "@/components/scheduling/EventManageSettings.vue";

export default {
  name: "scheduling-event-edit",
  components: {
    NavigationBar,
    EventManageDetails,
    EventManageSettings,
  },
  data() {
    return {
      actions: [
        {
          title: "Speichern",
          icon: "mdi-content-save",
          actionStyle: "textBtnSubmit",
          function: this.updateItem,
        },
      ],

      helpers: {
        formIsValid: false,
        modals: {
          vorlage: false,
        },
        props: {},
      },
      data: {
        title: "",
        description: "",
        type: {
          id: "",
        },
        location: {
          name: "",
        },
        requiredPPE: [],
        tasks: [],
        units: [],
        duration: {
          start: {
            timestamp: null,
            date: null,
            time: null,
            isOpen: false,
          },
          end: {
            timestamp: null,
            date: null,
            time: null,
            isOpen: false,
          },
        },

        details: [],

        config: {
          organizer: {
            isAppointed: false,
            hasAdditionalPermissions: false,
            description: null,
            personIds: [],
            personData: [],
          },
          attendance: {
            isMandatory: false,
            isLimited: false,
            description: null,
            minAttendees: null,
            maxAttendees: null,
            allowedAttendeesIds: [],
            allowedPersons: [],
            allowedUserGroups: [],
          },
          responses: {
            areBinding: false,
            areLocked: false,
            deadline: {
              isCustom: false,
              timestamp: null,
              date: null,
              time: null,
            },
          },
        },
      },
    };
  },
  computed: {},
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      db.collection("organizations")
        .doc(this.$route.params.organizationId)
        .collection("events")
        .doc(this.$route.params.itemId)
        .get()
        .then((doc) => {
          if (doc.exists) {
            this.data = doc.data();
            (this.data.duration.start.date = this.convertToDate(
              this.data.duration.start.timestamp
            )),
              (this.data.duration.start.time = this.convertToTime(
                this.data.duration.start.timestamp
              )),
              (this.data.duration.end.date = this.convertToDate(
                this.data.duration.end.timestamp
              )),
              (this.data.duration.end.time = this.convertToTime(
                this.data.duration.end.timestamp
              ));
            this.data.config.responses.deadline.date = this.convertToDate(
              this.data.config.responses.deadline.timestamp
            );
            this.data.config.responses.deadline.time = this.convertToTime(
              this.data.config.responses.deadline.timestamp
            );
          } else {
            // doc.data() will be undefined in this case
            console.log("No such document!");
          }
        })
        .catch(function (error) {
          console.log("Error getting document:", error);
          return false;
        });
    },
    validate() {
      this.$refs.form.validate();
    },
    convertToTimestamp(date, time) {
      const input = date + " " + time;
      return Timestamp.fromDate(new Date(input.replace(/-/g, "/")));
    },
    convertToDate(date) {
      date = date.toDate();
      return (
        date.getFullYear() +
        "-" +
        (date.getMonth() + 1 < 10 ? "0" : "") +
        (date.getMonth() + 1) +
        "-" +
        (date.getDate() < 10 ? "0" : "") +
        date.getDate()
      );
    },
    convertToTime(time) {
      time = time.toDate();
      return (
        (time.getHours() < 10 ? "0" : "") +
        time.getHours() +
        ":" +
        (time.getMinutes() < 10 ? "0" : "") +
        time.getMinutes()
      );
    },
    checkAttendeesData() {
      const hasItems = (array) => array.length > 0;
      const filterOutAllString = (array) =>
        array.filter((item) => item !== "all");
      const filterOutAllObject = (array) =>
        array.filter((item) => item.id != "all");

      const attendance = this.data.config.attendance;
      const allowedPersonsIds = attendance.allowedPersons.map(
        (item) => item.uid
      );
      const allowedUserGroupsIds = attendance.allowedUserGroups
        .map((item) => item.id)
        .filter((item) => item != "all");

      const attendanceIsLimited = attendance.isLimited;
      const noAttendanceDescription = attendance.description ? false : true;
      const noAttendanceLimitationsSelected =
        allowedPersonsIds.length == 0 && allowedUserGroupsIds.length == 0;

      // if no persons or user groups are selected, make sure all is included as default
      if (noAttendanceLimitationsSelected) {
        attendance.allowedAttendeesIds = filterOutAllString(
          attendance.allowedAttendeesIds
        );
        attendance.allowedUserGroups = [{ title: "Alle", id: "all" }];
      }
      // 1: else if user groups but no persons are selected, make sure all is not included
      // 2: else if persons but no user groups are selected, make sure all is not included
      // 3: else if persons and user groups are selected, make sure all is not included
      else if (
        (allowedPersonsIds.length == 0 && hasItems(allowedUserGroupsIds)) ||
        (hasItems(allowedPersonsIds) && allowedUserGroupsIds.length == 0) ||
        (hasItems(allowedPersonsIds) && hasItems(allowedUserGroupsIds))
      ) {
        attendance.allowedAttendeesIds = filterOutAllString(
          attendance.allowedAttendeesIds
        );
        attendance.allowedUserGroups = filterOutAllObject(
          attendance.allowedUserGroups
        );
      } else {
        console.log("error in attendance data");
      }

      if (!attendanceIsLimited) {
        attendance.allowedUserGroups = [{ title: "Alle", id: "all" }];
        attendance.allowedPersons = [];
      }

      if (
        attendanceIsLimited &&
        noAttendanceDescription &&
        noAttendanceLimitationsSelected
      ) {
        attendance.isLimited = false;
      }

      const personAttendanceIds = attendance.allowedPersons.map(
        (item) => item.uid
      );
      const userGroupAttendanceIds = attendance.allowedUserGroups.map(
        (item) => item.id
      );

      attendance.allowedAttendeesIds = personAttendanceIds.concat(
        userGroupAttendanceIds
      );
    },
    updateItem() {
      this.checkAttendeesData();

      this.validate();
      if (this.helpers.formIsValid) {
        if (
          this.convertToTimestamp(
            this.data.duration.start.date,
            this.data.duration.start.time
          ).valueOf() >
          this.convertToTimestamp(
            this.data.duration.end.date,
            this.data.duration.end.time
          ).valueOf()
        ) {
          alert(
            "Fehler: Das Startdatum der Veranstaltung muss vor dem Enddatum liegen."
          );
        } else if (
          this.data.config.responses.deadline.isCustom &&
          this.convertToTimestamp(
            this.data.duration.start.date,
            this.data.duration.start.time
          ).valueOf() <
            this.convertToTimestamp(
              this.data.config.responses.deadline.date,
              this.data.config.responses.deadline.time
            ).valueOf()
        ) {
          alert(
            "Fehler: Die Rückmeldefrist muss identisch mit oder vor dem Startdatum der Veranstaltung liegen."
          );
        } else {
          this.$store.dispatch(`${SCHEDULING}${UPDATE_EVENT}`, {
            organizationId: this.$route.params.organizationId,
            itemId: this.$route.params.itemId,

            title: this.data.title,
            description: this.data.description,
            type: {
              id: this.data.type.id,
              title: this.data.type.title,
              shortTitle: this.data.type.shortTitle,
              description: this.data.type.description,
              color: this.data.type.color,
            },
            location: {
              name: this.data.location.name,
            },
            requiredPPE: this.data.requiredPPE,
            tasks: this.data.tasks,
            units: this.data.units,
            duration: {
              start: {
                timestamp: this.convertToTimestamp(
                  this.data.duration.start.date,
                  this.data.duration.start.time
                ),
                isOpen: this.data.duration.start.isOpen,
              },
              end: {
                timestamp: this.convertToTimestamp(
                  this.data.duration.end.date,
                  this.data.duration.end.time
                ),
                isOpen: this.data.duration.end.isOpen,
              },
            },

            details: [],

            config: {
              organizer: {
                isAppointed: this.data.config.organizer.isAppointed,
                hasAdditionalPermissions:
                  this.data.config.organizer.hasAdditionalPermissions,
                description: this.data.config.organizer.description,
                personIds: this.data.config.organizer.personIds,
                personData: this.data.config.organizer.personData,
              },
              attendance: {
                isMandatory: this.data.config.attendance.isMandatory,
                isLimited: this.data.config.attendance.isLimited,
                description: this.data.config.attendance.description,
                minAttendees: this.data.config.attendance.minAttendees,
                maxAttendees: this.data.config.attendance.maxAttendees,
                allowedAttendeesIds:
                  this.data.config.attendance.allowedAttendeesIds,
                allowedPersons: this.data.config.attendance.allowedPersons,
                allowedUserGroups:
                  this.data.config.attendance.allowedUserGroups,
              },
              responses: {
                areBinding: this.data.config.responses.areBinding,
                areLocked: this.data.config.responses.areLocked,
                deadline: {
                  isCustom: this.data.config.responses.deadline.isCustom,
                  timestamp: this.convertToTimestamp(
                    this.data.config.responses.deadline.date,
                    this.data.config.responses.deadline.time
                  ),
                },
              },
            },
          });
        }
      } else {
        alert("Prüfe Deine Eingabe und versuche es erneut.");
      }
    },
    // Modals:
    // @Close:
    closeWAuswahlVorlage() {
      history.back();
    },
    // @Clicked
    clickedWAuswahlVorlage(value) {
      //this.helpers.modals.vorlage = !this.helpers.modals.vorlage;
      // Set data from template
      console.log(value);
    },
  },
};
</script>

<style></style>
