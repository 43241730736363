<template>
  <div>
    <NavigationBar
      titel="Neue Veranstaltung erstellen"
      showBackBtn
      :actions="actions"
    ></NavigationBar>
    <section>
      <template>
        <v-container>
          <v-row justify="center" align="center">
            <v-container v-if="$route.query.fromTemplate == true">
              <v-col cols="12">
                <Subheader
                  icon="mdi-book"
                  title="Vorlage auswählen"
                ></Subheader>
                <div class="mt-6">
                  <v-card>
                    <v-card-subtitle
                      >Wähle eine Vorlage aus, um die neue Veranstaltung
                      basierend auf vorhandenen Daten und Voreinstellungen
                      anzulegen. In jedem Fall muss das Start- sowie Enddatum
                      weiterhin angegeben werden.</v-card-subtitle
                    >
                    <v-card-text>
                      <v-container>
                        <v-row>
                          <v-col cols="12" sm="12" md="6">
                            <v-select
                              v-model="template"
                              :items="eventTemplates"
                              item-text="template.title"
                              item-value="meta.id"
                              class="pt-1"
                              return-object
                              label="Vorlage auswählen"
                              no-data-text="Keine Vorlagen vorhanden"
                              outlined
                              :loading="helpers.templateSelector.loading"
                              :disabled="helpers.templateSelector.loading"
                            >
                            </v-select>
                          </v-col>
                        </v-row>
                      </v-container>
                      <v-card-actions class="mt-n8">
                        <v-spacer></v-spacer>
                        <v-btn
                          depressed
                          :disabled="template == null"
                          @click="loadTemplate()"
                        >
                          Daten aus Vorlage übernehmen
                          <v-icon right> mdi-book-arrow-right </v-icon>
                        </v-btn>
                      </v-card-actions>
                    </v-card-text>
                  </v-card>
                </div>
              </v-col>
            </v-container>
            <v-form
              v-model="helpers.formIsValid"
              ref="form"
              @submit.prevent="createItem"
            >
              <v-col cols="12">
                <event-manage-details v-model="data"></event-manage-details>
              </v-col>
              <v-col cols="12">
                <event-manage-settings
                  v-model="data.config"
                ></event-manage-settings>
              </v-col>
            </v-form>
          </v-row>
        </v-container>
      </template>
    </section>
    <v-snackbar
      v-model="helpers.snackbar.isVisible"
      class="pb-4"
      bottom
      app
      timeout="4000"
      >{{ helpers.snackbar.message }}
    </v-snackbar>
  </div>
</template>

<script>
import { Timestamp } from "@/firebase";
import { SCHEDULING } from "@/store/modules.js";
import { CREATE_EVENT, GET_EVENT_TEMPLATES } from "@/store/action-types.js";
import NavigationBar from "@/components/_systemwide/NavigationBar.vue";
import Subheader from "@/components/_systemwide/Subheader.vue";
import EventManageDetails from "@/components/scheduling/EventManageDetails.vue";
import EventManageSettings from "@/components/scheduling/EventManageSettings.vue";

export default {
  name: "scheduling-event-new",
  components: {
    NavigationBar,
    Subheader,
    EventManageDetails,
    EventManageSettings,
  },
  data() {
    return {
      model: null,
      actions: [
        {
          title: "Erstellen",
          icon: "mdi-plus-circle",
          actionStyle: "textBtnSubmit",
          function: this.createItem,
        },
      ],

      helpers: {
        formIsValid: false,
        templateSelector: {
          loading: false,
        },
        snackbar: {
          isVisible: false,
          message: "Rückmeldefrist aufgrund von Vorlage aktualisiert",
        },
      },
      template: null,
      data: {
        title: null,
        description: null,
        type: {
          id: null,
          title: null,
          shortTitle: null,
          color: null,
          description: null,
        },
        location: {
          name: null,
        },
        requiredPPE: [],
        tasks: [],
        units: [],
        duration: {
          start: {
            date: null,
            time: null,
            timestamp: null,
            isOpen: false,
          },
          end: {
            date: null,
            time: null,
            timestamp: null,
            isOpen: false,
          },
        },
        details: [],
        config: {
          organizer: {
            isAppointed: false,
            hasAdditionalPermissions: false,
            description: "",
            personIds: [],
            personData: [],
          },
          attendance: {
            isMandatory: false,
            isLimited: false,
            description: null,
            minAttendees: null,
            maxAttendees: null,
            allowedAttendeesIds: ["all"],
            allowedPersons: [],
            allowedUserGroups: [
              {
                title: "Alle",
                id: "all",
              },
            ],
          },
          responses: {
            areBinding: false,
            areLocked: false,
            deadline: {
              isCustom: false,
              date: null,
              time: null,
              timestamp: null,
            },
          },
        },
      },
    };
  },
  computed: {
    eventTemplates() {
      return this.$store.state.scheduling.eventTemplates;
    },
  },
  watch: {
    $route: "initialize",
    "data.duration.start.date": function (newValue) {
      if (
        this.template &&
        this.template.data.config.responses.deadline.isCustom
      ) {
        if (newValue && this.data.duration.start.time) {
          this.calculateDeadline(
            this.template.data.config.responses.deadline.inMinutes
          );
        }
      }
    },
    "data.duration.start.time": function (newValue) {
      if (
        this.template &&
        this.template.data.config.responses.deadline.isCustom
      ) {
        if (this.data.duration.start.date && newValue) {
          this.calculateDeadline(
            this.template.data.config.responses.deadline.inMinutes
          );
        }
      }
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      if (this.$route.query.fromTemplate == true) {
        this.helpers.templateSelector.loading = true;
        this.$store
          .dispatch(`${SCHEDULING}${GET_EVENT_TEMPLATES}`, {
            organizationId: this.$route.params.organizationId,
          })
          .then(() => {
            this.helpers.templateSelector.loading = false;
          });
      }
    },
    convertToTimestamp(date, time) {
      const input = date + " " + time;
      return Timestamp.fromDate(new Date(input.replace(/-/g, "/")));
    },
    convertToDate(date) {
      date = date.toDate();
      return (
        date.getFullYear() +
        "-" +
        (date.getMonth() + 1 < 10 ? "0" : "") +
        (date.getMonth() + 1) +
        "-" +
        (date.getDate() < 10 ? "0" : "") +
        date.getDate()
      );
    },
    convertToTime(time) {
      time = time.toDate();
      return (
        (time.getHours() < 10 ? "0" : "") +
        time.getHours() +
        ":" +
        (time.getMinutes() < 10 ? "0" : "") +
        time.getMinutes()
      );
    },
    calculateDeadline(deadlineInMinutes) {
      var durationInMin = deadlineInMinutes;
      var MS_PER_MINUTE = 60000;
      var enddate = this.convertToTimestamp(
        this.data.duration.start.date,
        this.data.duration.start.time
      ).toDate();
      var result = Timestamp.fromDate(
        new Date(enddate - durationInMin * MS_PER_MINUTE)
      );
      this.data.config.responses.deadline.date = null;
      this.data.config.responses.deadline.time = null;
      this.data.config.responses.deadline.date = this.convertToDate(result);
      this.data.config.responses.deadline.time = this.convertToTime(result);
      this.helpers.snackbar.isVisible = true;
    },
    validate() {
      this.$refs.form.validate();
    },
    loadTemplate() {
      this.data = {
        title: this.template.data.title,
        description: this.template.data.description,
        type: {
          id: this.template.data.type.id,
          title: this.template.data.type.title,
          shortTitle: this.template.data.type.shortTitle,
          color: this.template.data.type.color,
          description: this.template.data.type.description,
        },
        location: {
          name: this.template.data.location.name,
        },
        requiredPPE: this.template.data.requiredPPE,
        tasks: this.template.data.tasks,
        units: this.template.data.units,
        duration: {
          start: {
            time: null,
            isOpen: this.template.data.duration.start.isOpen,
          },
          end: {
            time: null,
            isOpen: this.template.data.duration.end.isOpen,
          },
        },
        details: this.template.data.details,

        config: {
          organizer: {
            isAppointed: this.template.data.config.organizer.isAppointed,
            hasAdditionalPermissions:
              this.template.data.config.organizer.hasAdditionalPermissions,
            description: this.template.data.config.organizer.description,
            personIds: this.template.data.config.organizer.personIds,
            personData: this.template.data.config.organizer.personData,
          },
          attendance: {
            isMandatory: this.template.data.config.attendance.isMandatory,
            isLimited: this.template.data.config.attendance.isLimited,
            description: this.template.data.config.attendance.description,
            minAttendees: this.template.data.config.attendance.minAttendees,
            maxAttendees: this.template.data.config.attendance.maxAttendees,
            allowedAttendeesIds:
              this.template.data.config.attendance.allowedAttendeesIds,
            allowedUserGroups:
              this.template.data.config.attendance.allowedUserGroups,
            allowedPersons: this.template.data.config.attendance.allowedPersons,
          },
          responses: {
            areBinding: this.template.data.config.responses.areBinding,
            areLocked: this.template.data.config.responses.areLocked,
            deadline: {
              isCustom: this.template.data.config.responses.deadline.isCustom,
              timestamp: null,
              // inMinutes: this.template.data.config.responses.deadline.inMinutes
            },
          },
        },
      };
      this.data.duration.start.time = this.template.data.duration.start.time;
      this.data.duration.end.time = this.template.data.duration.end.time;
    },
    checkAttendeesData() {
      const hasItems = (array) => array.length > 0;
      const filterOutAllString = (array) =>
        array.filter((item) => item !== "all");
      const filterOutAllObject = (array) =>
        array.filter((item) => item.id != "all");

      const attendance = this.data.config.attendance;
      const allowedPersonsIds = attendance.allowedPersons.map(
        (item) => item.uid
      );
      const allowedUserGroupsIds = attendance.allowedUserGroups
        .map((item) => item.id)
        .filter((item) => item != "all");

      const attendanceIsLimited = attendance.isLimited;
      const noAttendanceDescription = attendance.description ? false : true;
      const noAttendanceLimitationsSelected =
        allowedPersonsIds.length == 0 && allowedUserGroupsIds.length == 0;

      // if no persons or user groups are selected, make sure all is included as default
      if (noAttendanceLimitationsSelected) {
        attendance.allowedAttendeesIds = filterOutAllString(
          attendance.allowedAttendeesIds
        );
        attendance.allowedUserGroups = [{ title: "Alle", id: "all" }];
      }
      // 1: else if user groups but no persons are selected, make sure all is not included
      // 2: else if persons but no user groups are selected, make sure all is not included
      // 3: else if persons and user groups are selected, make sure all is not included
      else if (
        (allowedPersonsIds.length == 0 && hasItems(allowedUserGroupsIds)) ||
        (hasItems(allowedPersonsIds) && allowedUserGroupsIds.length == 0) ||
        (hasItems(allowedPersonsIds) && hasItems(allowedUserGroupsIds))
      ) {
        attendance.allowedAttendeesIds = filterOutAllString(
          attendance.allowedAttendeesIds
        );
        attendance.allowedUserGroups = filterOutAllObject(
          attendance.allowedUserGroups
        );
      } else {
        console.log("error in attendance data");
      }

      if (!attendanceIsLimited) {
        attendance.allowedUserGroups = [{ title: "Alle", id: "all" }];
        attendance.allowedPersons = [];
      }

      if (
        attendanceIsLimited &&
        noAttendanceDescription &&
        noAttendanceLimitationsSelected
      ) {
        attendance.isLimited = false;
      }

      const personAttendanceIds = attendance.allowedPersons.map(
        (item) => item.uid
      );
      const userGroupAttendanceIds = attendance.allowedUserGroups.map(
        (item) => item.id
      );

      attendance.allowedAttendeesIds = personAttendanceIds.concat(
        userGroupAttendanceIds
      );
    },
    createItem() {
      this.checkAttendeesData();

      this.$refs.form.validate();
      if (this.helpers.formIsValid) {
        if (
          this.convertToTimestamp(
            this.data.duration.start.date,
            this.data.duration.start.time
          ).valueOf() >
          this.convertToTimestamp(
            this.data.duration.end.date,
            this.data.duration.end.time
          ).valueOf()
        ) {
          alert(
            "Fehler: Das Startdatum der Veranstaltung muss vor dem Enddatum liegen."
          );
        } else if (
          this.data.config.responses.deadline.isCustom &&
          this.convertToTimestamp(
            this.data.duration.start.date,
            this.data.duration.start.time
          ).valueOf() <
            this.convertToTimestamp(
              this.data.config.responses.deadline.date,
              this.data.config.responses.deadline.time
            ).valueOf()
        ) {
          alert(
            "Fehler: Die Rückmeldefrist muss identisch mit oder vor dem Startdatum der Veranstaltung liegen."
          );
        } else {
          this.$store.dispatch(`${SCHEDULING}${CREATE_EVENT}`, {
            organizationId: this.$route.params.organizationId,

            title: this.data.title,
            description: this.data.description,
            type: {
              id: this.data.type.id,
              title: this.data.type.title,
              shortTitle: this.data.type.shortTitle,
              description: this.data.type.description,
              color: this.data.type.color,
            },
            location: {
              name: this.data.location.name,
            },
            requiredPPE: this.data.requiredPPE,
            tasks: this.data.tasks,
            units: this.data.units,
            duration: {
              start: {
                timestamp: this.convertToTimestamp(
                  this.data.duration.start.date,
                  this.data.duration.start.time
                ),
                isOpen: this.data.duration.start.isOpen,
              },
              end: {
                timestamp: this.convertToTimestamp(
                  this.data.duration.end.date,
                  this.data.duration.end.time
                ),
                isOpen: this.data.duration.end.isOpen,
              },
            },

            details: [],

            config: {
              organizer: {
                isAppointed: this.data.config.organizer.isAppointed,
                hasAdditionalPermissions:
                  this.data.config.organizer.hasAdditionalPermissions,
                description: this.data.config.organizer.description,
                personIds: this.data.config.organizer.personIds,
                personData: this.data.config.organizer.personData,
              },
              attendance: {
                isMandatory: this.data.config.attendance.isMandatory,
                isLimited: this.data.config.attendance.isLimited,
                description: this.data.config.attendance.description,
                minAttendees: this.data.config.attendance.minAttendees,
                maxAttendees: this.data.config.attendance.maxAttendees,
                allowedAttendeesIds:
                  this.data.config.attendance.allowedAttendeesIds,
                allowedPersons: this.data.config.attendance.allowedPersons,
                allowedUserGroups:
                  this.data.config.attendance.allowedUserGroups,
              },
              responses: {
                areBinding: this.data.config.responses.areBinding,
                areLocked: this.data.config.responses.areLocked,
                deadline: {
                  isCustom: this.data.config.responses.deadline.isCustom,
                  timestamp: this.convertToTimestamp(
                    this.data.config.responses.deadline.date,
                    this.data.config.responses.deadline.time
                  ),
                },
              },
            },
          });
        }
      } else {
        alert("Prüfe Deine Eingabe und versuche es erneut.");
      }
    },
  },
};
</script>

<style></style>
